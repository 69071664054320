import React, { useState } from 'react'
import './Header.css'
import logo from './Logo.svg'
import { Icon } from 'antd'
import Drawer from './Drawer'

function Header() {
    const [visible, setVisibility] = useState(false)
    return (
        <div className="Header">
            { false && <Icon type="menu-unfold" className="Header-menuButton" onClick={() => setVisibility(true)} /> }
            <img src={logo} className="Header-logo" alt="logo" />
            { false && <Drawer visible={visible} setVisibility={setVisibility} />}
        </div>
    )
}

export default Header
