let cb;
let i = 0;
let questions;
function foo(setQuestion, setLoading) {
  if (cb) {
    return cb;
  }
  fetch("https://aquizguru-questions.s3.eu-west-2.amazonaws.com/AWS-SAP.json")
    .then((_) => _.json())
    .then((_) => {
      questions = _.questions;
      setQuestion(_.questions[i++]);
      setLoading(false);
    });
  cb = () => {
    if (i >= questions.length) {
      setQuestion(false);
      return;
    }
    setQuestion(questions[i++]);
  };
  return cb;
}

export default foo;
