import React, { useState } from "react";
import "./Question.css";
import Card from "../components/Card";
import { Skeleton } from "antd";
import { Button, Checkbox, Radio, message } from "antd";
import { useEffect } from "react";
import styled from "styled-components";

const Link = styled.a`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function Choice({
  choice,
  i,
  isMultiAnswers,
  isConfirmed,
  isRight,
  isSelected,
}) {
  const SelectionType = isMultiAnswers ? Checkbox : Radio;
  let className = "";
  if (isConfirmed) {
    if (isRight) {
      className = "Question-choice--success";
    } else if (isSelected) {
      className = "Question-choice--failure";
    }
  }
  return (
    <p>
      <SelectionType value={i} disabled={!!isConfirmed} className={className}>
        {" "}
        {choice}{" "}
      </SelectionType>
    </p>
  );
}

function Choices({
  choices,
  answers,
  isConfirmed,
  isMultiAnswers,
  setSelectedValues,
  selectedValues,
}) {
  const Wrapper = isMultiAnswers ? Checkbox.Group : Radio.Group;
  const value = isMultiAnswers
    ? selectedValues
    : selectedValues && selectedValues[0];

  function handleSelectionChange(event) {
    const selection = isMultiAnswers ? event : [event.target.value];
    setSelectedValues(selection);
  }

  return (
    <Wrapper onChange={handleSelectionChange} value={value}>
      {choices.map((choice, i) => (
        <Choice
          key={choice}
          i={i}
          isMultiAnswers={isMultiAnswers}
          isConfirmed={isConfirmed}
          choice={choice}
          isRight={answers.indexOf(i) !== -1}
          isSelected={selectedValues.indexOf(i) !== -1}
        />
      ))}
    </Wrapper>
  );
}

/*

 (!isMultiAnswers ?
                        <Radio.Group onChange={onRadioChange} value={radioValue}>
                            { question.choices.map((choice, i) => {
                                let className = ''
                                if (isConfirmed) {
                                    if (question.answers[0] === i) {
                                        className = 'Question-choice--success'
                                    } else if (radioValue === i) {
                                        className = 'Question-choice--failure'
                                    }
                                }
                                return <p key={choice} className={className}> <Radio value={i} disabled={!!isConfirmed} /> {choice} </p>
                            }) }
                        </Radio.Group> :
                        question.choices.map((choice, i) => <p key={choice} > <Checkbox onChange={(e) => onCheckboxChange(i, e)} /> {choice} </p>))

                        */

function Question({ isLoading, question, nextAction }) {
  const isMultiAnswers =
    question && question.answers && question.answers.length > 1;
  const [isConfirmed, setConfirm] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  function confirm() {
    if (selectedValues.sort().join("") === question.answers.sort().join("")) {
      message.success("Well done!");
    } else {
      message.error("Sorry, wrong answer :(");
    }
    setConfirm(true);
  }

  useEffect(() => {
    setSelectedValues([]);
    setConfirm(false);
  }, [question]);

  return (
    <div className="Question">
      <Card>
        {isLoading ? (
          <Skeleton
            paragraph={{ rows: 4, width: "100%" }}
            title={false}
            active
          />
        ) : (
          question.question
        )}
      </Card>
      <Card>
        {isLoading ? (
          <Skeleton
            paragraph={{ rows: 6, width: "100%" }}
            title={false}
            active
          />
        ) : (
          <Choices
            choices={question.choices}
            isConfirmed={isConfirmed}
            isMultiAnswers={isMultiAnswers}
            setSelectedValues={setSelectedValues}
            selectedValues={selectedValues}
            answers={question.answers}
          />
        )}
      </Card>
      {isConfirmed && question.explanations && question.explanations.length && (
        <Card>
          {question.explanations.map((url) => (
            <div>
              <Link href={url}>{url}</Link>
            </div>
          ))}
        </Card>
      )}
      <Card borderless>
        {!isConfirmed ? (
          <Button
            className="Question-button"
            disabled={isLoading}
            onClick={confirm}
          >
            Confirm
          </Button>
        ) : (
          <Button
            className="Question-button"
            disabled={isLoading}
            onClick={nextAction}
          >
            Next
          </Button>
        )}
      </Card>
    </div>
  );
}

export default Question;
