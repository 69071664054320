import React from 'react'
import './Card.css'
import { Card } from 'antd'

function MyCard({children, borderless}) {
  return (
    <Card className={`Card ${borderless ? 'Card--borderless': ''}`}>
        {children}
    </Card>
  )
}

export default MyCard
