import React, { useState, useEffect } from 'react'
import './App.css'
import Header from './components/Header'
import Footer from './components/Footer'
import Content from './components/Content'
import Question from './pages/Question'
import RandomQuestions from './models/RandomQuestions'
import { Empty } from 'antd'

function App() {
  const [question, setQuestion] = useState()
  const [isLoading, setLoading] = useState(true)
  const nextAction = RandomQuestions(setQuestion, setLoading)
  return (
    <div className="App">
      <Header />
      <Content>
        { !isLoading && !question && <Empty description="No other questions" image={Empty.PRESENTED_IMAGE_SIMPLE} /> }
        { (Boolean(question) || isLoading) && <Question isLoading={isLoading} question={question} nextAction={nextAction} /> }
      </Content>
      <Footer />
    </div>
  )
}

export default App
